import React from "react";
import { Link } from "react-router-dom";

const Section_ComeBeaLion = () => {
    return (
        <>
            <section class="team" >
                <div class="main__shadow_team mt-5 py-5">
                    <div class="container">
                        <div class="center__view py-4">
                            <h1 class="text-white">Come, be a Lion!</h1>
                            <p class="text-white">Join your hand with us for a better life and future</p>
                            <a href="/docs/member-application-form-2023.pdf" class="btn__more" target={"_blank"}>Download Form</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Section_ComeBeaLion;
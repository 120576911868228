import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import SubMenu from '../components/submenu/about';
import Section_ComeBeaLion from '../components/section-come-be-a-lion';
import Section_BecomePartner from '../components/section-become-partner';
import HeaderLogo from "../components/headerlogo";
import SEO from "../components/seo";
import { Link } from "react-router-dom";

const OurTeam = () => {

  useEffect(() => {
    window.scrollTo(0, 500)
  }, [])


  return (

    <>
      <SEO
        title='Team'
        description='.'
        keywords=''
      />
      <Header activelink='about' />
      <main>
        <section className="type-baner">
          <img src="images/aboutnew-banner.png" alt="" width="100%" height="100%" />



        </section>
      </main>
      <main>

        <section>
          <div className="container mt-5">
            <div className="content-about">
              <span className="management">Our Leadership Team</span>
              <h2 className="">
                The champions of a <br /> construction revolution
              </h2>




            </div>


            <div className="row">
              {/* <div className="col-lg-3 col-md-6 mt-5">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <div className="card-image-section-team">
                        <img src="images/Sanat Jain (Group Chairman).jpg" alt="" />
                        <div className="card-image-head">
                          Sanat Jain
                        </div>
                        <div className="card-image-byline">
                          Group Chairman
                        </div>
                      </div>
                    </div>
                    <div className="flip-card-back">
                      <div className="contain-about-more">
                        <div className="about-section-card">

                          <p>Sanat Jain mirrors the values of the organisation he heads - professionalism, entrepreneurship and a passionate commitment to advancing the interests of all stakeholders. Under his leadership, AS Krishna Associates Pvt. LTD. overcame multiple challenges and emerged stronger with a sharper focus on profitable growth.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div> */}
             <div className="col-lg-3 col-md-6 mt-5">


<div className="flip-card">
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <div className="card-image-section-team">
        <img src="images/ajaygoyal-01.jpg" alt="" />
        <div className="card-image-head">
          Ajay Goel
        </div>
        <div className="card-image-byline">
        CEO
        </div>
      </div>
    </div>
    <div className="flip-card-back">
      <div className="contain-about-more">
        <div className="about-section-card">

          <p>

            With decades of experience across domains, he has spearheaded the growth
            strategy for the commercial and residential building businesses, and has been
            instrumental in securing a number of mega orders. He has held and continues to
            play vital roles in various industry bodies, construction institutions, and councils.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
<div className="col-lg-3 col-md-6 mt-5">


<div className="flip-card">
<div className="flip-card-inner">
<div className="flip-card-front">
<div className="card-image-section-team">
<img src="images/Amit_Jain_(CEO & Director).jpg" alt="" />
<div className="card-image-head">
Amit Jain
</div>
<div className="card-image-byline">
CFO
</div>
</div>
</div>
<div className="flip-card-back">
<div className="contain-about-more">
<div className="about-section-card">

<p>Over the past 15 years, Mr. Amit Jain has worked in various capacities in the
field of finance. He was also instrumental in establishing an enterprise-wide
risk management framework for the company. Presently, he oversees the finance
functions across Askapl.
</p>
</div>
</div>
</div>
</div>
</div>

</div>
<div className="col-lg-3 col-md-6 mt-5">


<div className="flip-card">
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <div className="card-image-section-team">
        <img src="images/sample-profile.jpg" alt="" />
        <div className="card-image-head">
          Sourabh Jain
        </div>
        <div className="card-image-byline">
        Director
        </div>
      </div>
    </div>
    <div className="flip-card-back">
      <div className="contain-about-more">
        <div className="about-section-card">

          <p>

          With over 20 years of experience in Trading and Building Constructions, he embodies the values 
          of professionalism, entrepreneurship, and a passionate commitment to advancing the interests of 
          all stakeholders within the organization.
  </p>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
<div className="col-lg-3 col-md-6 mt-5">


<div className="flip-card">
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <div className="card-image-section-team">
        <img src="images/sample-profile.jpg" alt="" />
        <div className="card-image-head">
          Gaurav jain
        </div>
        <div className="card-image-byline">
        Director
        </div>
      </div>
    </div>
    <div className="flip-card-back">
      <div className="contain-about-more">
        <div className="about-section-card">

          <p>

          With over 18 years of experience in the fields of Trading, Constructions,
           and on-site work, he has developed a wealth of expertise and knowledge that is unparalleled in the industry.
  </p>
        </div>
      </div>
    </div>
  </div>
</div>

</div>

{/* <div className="col-lg-3 col-md-6 mt-5">


<div className="flip-card">
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <div className="card-image-section-team">
        <img src="images/sample-profile.jpg" alt="" />
        <div className="card-image-head">
          Sambhav Jain
        </div>
        <div className="card-image-byline">
          Chief Financial Officer (CFO)
        </div>
      </div>
    </div>
    <div className="flip-card-back">
      <div className="contain-about-more">
        <div className="about-section-card">

          <p>An Associated Member of ICAI and having 10+ years of experience in Finance and Tax Audit. He mirrors the organisation’s professionalism and a
             passionate commitment to advancing the interests of AS Krishna Associates Limited. </p>

        </div>
      </div>
    </div>
  </div>
</div>

</div> */}

<div className="col-lg-3 col-md-6 mt-5">


<div className="flip-card">
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <div className="card-image-section-team">
        <img src="images/sample-profile.jpg" alt="" />
        <div className="card-image-head">
          Priya Gupta
        </div>
        <div className="card-image-byline">
        Company Secretary CS
        </div>
      </div>
    </div>
    <div className="flip-card-back">
      <div className="contain-about-more">
        <div className="about-section-card">

          <p>

          An Associate Member of ICSI, she has over 5.5 years of experience in Secretarial and Legal Department. She has liaised with various departments, including ROC, Income Tax, BSE, Stock Exchange, RTA, Depositories, and has worked with listed public companies.
</p>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
<div className="col-lg-3 col-md-6 mt-5">


<div className="flip-card">
<div className="flip-card-inner">
  <div className="flip-card-front">
    <div className="card-image-section-team">
      <img src="images/sample-profile.jpg" alt="" />
      <div className="card-image-head">
      Yashashvi
      </div>
      <div className="card-image-byline">
      Director
      </div>
    </div>
  </div>
  <div className="flip-card-back">
    <div className="contain-about-more">
      <div className="about-section-card">

        <p>

      
</p>
      </div>
    </div>
  </div>
</div>
</div>

</div>

            </div>
          </div>



        </section>
      </main>
      <br/>
      <Footer activelink='about' />
    </>
  )
}
export default OurTeam;

import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import SubMenu from '../components/submenu/about';
import Section_ComeBeaLion from '../components/section-come-be-a-lion';
import Section_BecomePartner from '../components/section-become-partner';
import HeaderLogo from "../components/headerlogo";
import SEO from "../components/seo";
import { Link } from "react-router-dom";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SEO
        title='About'
        description='.'
        keywords=''
      />
      <Header activelink='about' />

      <main>
        <section className="type-baner">
          <img src="images/aboutnew-banner.png" alt="" width="100%" height="100%" />



        </section>
      </main>


      <div className="container mt-5">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-6">
            <div className="content-about">
              <span className="who-are">Who we are</span>
              <h2 className="mt-4">
                Passion Integrity <br /> Hard work Professionalism <br /> Caring.
              </h2>

              <p>
                AS Krishna Associates is a team of passionate and experienced professionals dedicated to delivering exceptional
                construction solutions. With a strong focus on quality, safety, and sustainability,
                we have established ourselves as a trusted name in the industry. Our commitment to
                innovation and cutting-edge technology ensures that we are always at the forefront of construction trends,
                delivering projects that meet and exceed expectations. From commercial buildings to waste management projects,
                we pride ourselves on our ability to manage complex construction processes with ease, ensuring timely delivery
                and cost-effectiveness.
                Trust us to bring your vision to life with unparalleled expertise and creativity.
              </p>


            </div>

            <div className="row">
              <div className="col-lg-4 certificate-box">
              <a href="images/AS KRISHNA ASSOCIATES LIMITED QRO EGAC 9001 305023012372Q_2015.pdf" target="_blank">
                 <img src="images/Certificate-2015.png" alt="" width="100%"  /></a>
              <span className="card-image-byline">ISO 9001:2015 </span>
              </div>
              <div className="col-lg-4 certificate-box">
                <a href="images/AS KRISHNA ASSOCIATES LIMITED QRO EGAC 45001 305023012373HS_2018.pdf" target="_blank">
              <img src="images/Certificate-2018.png" alt="" width="100%"  /></a>
              <span className="card-image-byline">ISO 45001:2018</span>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="image-about-section">
              <img src="images/about-section-new.png" alt="" width="100%" height="100%" />
              
            </div>
          </div>
        </div>
      </div>



      <main>
        <section className="about-mission-section mt-5">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-7 py-5">
                <div className="about-mision-content py-5">
                  <div className="content-about">
                    <span className="mission-span">Mission</span>
                    <h2 className="about-section-bold-bg">
                    Our mission is to be the driving force in India’s infrastructure growth,
                    focusing on quality, sustainability, and innovation, while consistently delivering projects on time with a team of skilled professionals.
                    </h2>

                    {/* <p className="text-white">
                      Quality is never an accident; it is always the result of high intention, sincere efforts, intelligent direction and skilful execution; it represents the wise choice of many alternatives. Every project that we undertake adheres to stringent quality standards and we ensure the completion of all projects in their stipulated time period.
                    </p> */}


                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
      </main>

      <main>

        <section>
          <div className="container mt-5">
            <div className="content-about">
              <span className="management">Our Leadership Team</span>
              <h2 className="">
                The champions of a <br /> construction revolution
              </h2>




            </div>


            <div className="row">
              {/* <div className="col-lg-3 col-md-6 mt-5">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <div className="card-image-section-team">
                        <img src="images/Sanat Jain (Group Chairman).jpg" alt="" />
                        <div className="card-image-head">
                          Sanat Jain
                        </div>
                        <div className="card-image-byline">
                          Group Chairman
                        </div>
                      </div>
                    </div>
                    <div className="flip-card-back">
                      <div className="contain-about-more">
                        <div className="about-section-card">

                          <p>Sanat Jain mirrors the values of the organisation he heads - professionalism, entrepreneurship and a passionate commitment to advancing the interests of all stakeholders. Under his leadership, AS Krishna Associates Pvt. LTD. overcame multiple challenges and emerged stronger with a sharper focus on profitable growth.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div> */}
              
              <div className="col-lg-3 col-md-6 mt-5">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <div className="card-image-section-team">
                        <img src="images/ajaygoyal-01.jpg" alt="" />
                        <div className="card-image-head">
                          Ajay Goel
                        </div>
                        <div className="card-image-byline">
                        CEO
                        </div>
                      </div>
                    </div>
                    <div className="flip-card-back">
                      <div className="contain-about-more">
                        <div className="about-section-card">

                          <p>

                            With decades of experience across domains, he has spearheaded the growth
                            strategy for the commercial and residential building businesses, and has been
                            instrumental in securing a number of mega orders. He has held and continues to
                            play vital roles in various industry bodies, construction institutions, and councils.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-3 col-md-6 mt-5">


<div className="flip-card">
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <div className="card-image-section-team">
        <img src="images/Amit_Jain_(CEO & Director).jpg" alt="" />
        <div className="card-image-head">
          Amit Jain
        </div>
        <div className="card-image-byline">
        CFO
        </div>
      </div>
    </div>
    <div className="flip-card-back">
      <div className="contain-about-more">
        <div className="about-section-card">

          <p>Over the past 15 years, Mr. Amit Jain has worked in various capacities in the
            field of finance. He was also instrumental in establishing an enterprise-wide
            risk management framework for the company. Presently, he oversees the finance
            functions across Askapl.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

              </div>
              <div className="col-lg-3 col-md-6 mt-5">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <div className="card-image-section-team">
                        <img src="images/sample-profile.jpg" alt="" />
                        <div className="card-image-head">
                          Sourabh Jain
                        </div>
                        <div className="card-image-byline">
                        Director
                        </div>
                      </div>
                    </div>
                    <div className="flip-card-back">
                      <div className="contain-about-more">
                        <div className="about-section-card">

                          <p>

                          With over 20 years of experience in Trading and Building Constructions, he embodies the values 
                          of professionalism, entrepreneurship, and a passionate commitment to advancing the interests of 
                          all stakeholders within the organization.
                  </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-3 col-md-6 mt-5">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <div className="card-image-section-team">
                        <img src="images/sample-profile.jpg" alt="" />
                        <div className="card-image-head">
                          Gaurav jain
                        </div>
                        <div className="card-image-byline">
                        Director
                        </div>
                      </div>
                    </div>
                    <div className="flip-card-back">
                      <div className="contain-about-more">
                        <div className="about-section-card">

                          <p>

                          With over 18 years of experience in the fields of Trading, Constructions,
                           and on-site work, he has developed a wealth of expertise and knowledge that is unparalleled in the industry.
                  </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              {/* <div className="col-lg-3 col-md-6 mt-5">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <div className="card-image-section-team">
                        <img src="images/sample-profile.jpg" alt="" />
                        <div className="card-image-head">
                          Sambhav Jain
                        </div>
                        <div className="card-image-byline">
                          Chief Financial Officer (CFO)
                        </div>
                      </div>
                    </div>
                    <div className="flip-card-back">
                      <div className="contain-about-more">
                        <div className="about-section-card">

                          <p>An Associated Member of ICAI and having 10+ years of experience in Finance and Tax Audit. He mirrors the organisation’s professionalism and a
                             passionate commitment to advancing the interests of AS Krishna Associates Limited. </p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div> */}

              <div className="col-lg-3 col-md-6 mt-5">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <div className="card-image-section-team">
                        <img src="images/sample-profile.jpg" alt="" />
                        <div className="card-image-head">
                          Priya Gupta
                        </div>
                        <div className="card-image-byline">
                        Company Secretary CS
                        </div>
                      </div>
                    </div>
                    <div className="flip-card-back">
                      <div className="contain-about-more">
                        <div className="about-section-card">

                          <p>

                          An Associate Member of ICSI, she has over 5.5 years of experience in Secretarial and Legal Department. She has liaised with various departments, including ROC, Income Tax, BSE, Stock Exchange, RTA, Depositories, and has worked with listed public companies.
  </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-3 col-md-6 mt-5">


              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="card-image-section-team">
                      <img src="images/sample-profile.jpg" alt="" />
                      <div className="card-image-head">
                      Yashashvi
                      </div>
                      <div className="card-image-byline">
                      Director
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="contain-about-more">
                      <div className="about-section-card">

                        <p>

                      
              </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

             </div>
             
              {/* <div className="col-lg-3 col-md-6 mt-5">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <div className="card-image-section-team">
                        <img src="images/sample-profile.jpg" alt="" />
                        <div className="card-image-head">
                          Shubhangi Agarwal
                        </div>
                        <div className="card-image-byline">
                          Additional Director (Independent)
                        </div>
                      </div>
                    </div>
                    <div className="flip-card-back">
                      <div className="contain-about-more">
                        <div className="about-section-card">

                          <p>
                          An Associated Member of ICSI and the Founder of M/s Shubhangi Agarwal & Associates, she has over 6 years of experience and has worked in all practice areas, including Secretarial, Legal Compliance Monitoring Systems, and IPR.
  </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div> */}

            </div>
          </div>



        </section>
      </main>
     
      <main className="mt-5 pt-5">
        <section className="build-project">

          <div className="container py-3">
            <div className="row">
              <div className="col-lg-12 py-5">
                <div className="content-build-project">
                  <h2>Ready to <br />
                    build together?</h2>
                </div>
                <div className="button-builf-project mt-5">
                  <Link to="/contact">Build a project with us</Link>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>



      <Footer activelink='about' />
    </>
  );
};

export default About;
import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { Link } from "react-router-dom";


function Service() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
  <SEO
        title='Service'
        description='.'
        keywords=''
      />

<Header activelink='service'/>


        <main>
         <section class="type-baner">
            <img src="images/service-new-banner.png" alt="" width="100%"  height="100%"/>

    
              
         </section>
      </main>
  




      <main>

          <section className="service-reponsive">
               
      
      <div class="container mt-5">
        <div class="">
      <div class="row d-flex justify-content-center">
          <div class="col-lg-6">
              <div class="content-about py-5">
                  <span class="Infrastrcuture">Infrastructure Developement </span>
                    <h2 class="mt-4">
    
                    Building Spaces That Inspire Success
                    </h2>
    
                  <p>
                  Transforming dreams into reality. From retail spaces to office buildings,
                   we deliver exceptional attention to detail, innovative design solutions, and expert
                    project management skills. Let AS Krishna Associates exceed your expectations and 
                    deliver a space that inspires success.
                    </p>
        
               
                 </div>
                 <div class="btn-lern-about mb-5">
                 <Link to="/projects" class="abot-learn-more">View projects</Link>
                </div>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-5 mt-5">
                <div class="image-servies-co">
                    <img src="images/Services-001.png" alt="" width="100%" height="100%"/>
                </div>
            </div>
      </div>
        </div>
      </div>
  
  
  
  
  
  
      <div class="container mt-5">
        <div class="">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-5 mt-5">
          <div class="image-servies-co">
              <img src="images/services-002.png" alt="" width="100%" height="100%"/>
          </div>
      </div>
      <div class="col-lg-1"></div>
          <div class="col-lg-6 mt-5">
              <div class="content-about ps-5">
                  <span class="commercial">Waste management</span>
                    <h2 class="mt-4">
    
                    Innovative Waste Management Solutions For Sustainable Future
                    </h2>
    
                  <p>
                  We construct innovative waste management systems that are sustainable, efficient, 
                  and effective. With a focus on responsible resource management and recycling, we are 
                  committed to creating a cleaner, greener future. Trust us to design and build a waste 
                  management system that works for your business and the environment.
                     </p>
                      <div class="btn-lern-about mt-5">
                        <Link to="/projects" class="abot-learn-more">View projects</Link>
                      </div>
                 </div>
               
            </div>
  
      
      </div>
        </div>
      </div>





      
      
      <div class="container mt-5">
        <div class="">
      <div class="row  d-flex justify-content-center">
          <div class="col-lg-6 mt-5">
              <div class="content-about py-5">
                  <span class="logistics">Construction Logistics </span>
                    <h2 class="mt-4">
    
                    Building Connections: More Than Just Highways
                    </h2>
    
                  <p>
                  We construct highways that connect communities and drive progress.
                   With a focus on safety, quality, and innovation, our experienced team 
                   delivers highways that are designed to last for generations. From planning 
                   and design to construction and maintenance, we are dedicated to delivering the
                    highest standards of excellence in highway construction. Choose us for your next highway
                     project and experience the difference that expertise makes.
                     </p>
        

               
                 </div>
                 <div class="btn-lern-about">
                 <Link to="/projects" class="abot-learn-more">View projects</Link>
                </div>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-5 mt-5">
                <div class="image-servies-co">
                    <img src="images/services-003.png" alt="" width="100%" height="100%"/>
                </div>
            </div>
      </div>
        </div>
      </div>
  
  
  
  
  
  








      
      
     
  
  
  
  
  
  
          </section>


          <section className="service-reponsive-001">
               
      
               <div class="container mt-5">
                 <div class="">
               <div class="row d-flex justify-content-center">
                   <div class="col-lg-6">
                       <div class="content-about py-5">
                           <span class="commercial">Commercial buildings</span>
                             <h2 class="mt-4">
             
                             Building Spaces That Inspire Success
                             </h2>
             
                           <p>
                           Transforming dreams into reality. From retail spaces to office buildings,
                            we deliver exceptional attention to detail, innovative design solutions, and expert
                             project management skills. Let AS Krishna Associates exceed your expectations and 
                             deliver a space that inspires success.
                             </p>
                 
                        
                          </div>
                          <div class="btn-lern-about mb-5">
                          <Link to="/projects" class="abot-learn-more">View project</Link>
                         </div>
                     </div>
                     <div class="col-lg-1"></div>
                     <div class="col-lg-5 mt-5">
                         <div class="image-servies-co">
                             <img src="images/Services-001.png" alt="" width="100%" height="100%"/>
                         </div>
                     </div>
               </div>
                 </div>
               </div>
           
           
           
           
           
           
               <div class="container mt-5">
                 <div class="">
               <div class="row d-flex justify-content-center">

               <div class="col-lg-6 mt-5">
                       <div class="content-about">
                           <span class="commercial">Waste management</span>
                             <h2 class="mt-4">
             
                             Innovative Waste Management Solutions For Sustainable Future
                             </h2>
             
                           <p>
                           We construct innovative waste management systems that are sustainable, efficient, 
                           and effective. With a focus on responsible resource management and recycling, we are 
                           committed to creating a cleaner, greener future. Trust us to design and build a waste 
                           management system that works for your business and the environment.
                              </p>
                               <div class="btn-lern-about mt-5">
                                 <Link to="/projects" class="abot-learn-more">View project</Link>
                               </div>
                          </div>
                        
                     </div>
                 <div class="col-lg-5 mt-5">
                   <div class="image-servies-co">
                       <img src="images/services-002.png" alt="" width="100%" height="100%"/>
                   </div>
               </div>
            
                   
           
               
               </div>
                 </div>
               </div>
         
         
         
         
         
               
               
               <div class="container mt-5">
                 <div class="">
               <div class="row  d-flex justify-content-center">
                   <div class="col-lg-6 mt-5">
                       <div class="content-about py-5">
                           <span class="log-bard">Highways</span>
                             <h2 class="mt-4">
             
                             Building Connections: More Than Just Highways
                             </h2>
             
                           <p>
                           We construct highways that connect communities and drive progress.
                            With a focus on safety, quality, and innovation, our experienced team 
                            delivers highways that are designed to last for generations. From planning 
                            and design to construction and maintenance, we are dedicated to delivering the
                             highest standards of excellence in highway construction. Choose us for your next highway
                              project and experience the difference that expertise makes.
                              </p>
                 
         
                        
                          </div>
                          <div class="btn-lern-about">
                          <Link to="/projects" class="abot-learn-more">View project</Link>
                         </div>
                     </div>
                     <div class="col-lg-1"></div>
                     <div class="col-lg-5 mt-5">
                         <div class="image-servies-co">
                             <img src="images/services-003.png" alt="" width="100%" height="100%"/>
                         </div>
                     </div>
               </div>
                 </div>
               </div>
           
           
           
           
           
           
         
         
         
         
         
         
         
         
               
               
              
           
           
           
           
           
           
                   </section>
      </main>



<main>
   <section class="our-fouc-section-service">
    <div class="container">
       <div class="row d-flex justify-content-center">
           <div class="col-lg-6 py-5">
              <div class="service-focus">
                <div class="content-about py-5">
                  <span class="log-bard">Our Focus</span>
                    <h2 class="mt-4">
    
                      Flexible, focused and <br/> innovative: we're focused on <br/> achieving your project vision   <br/> and business goals.
                   
                    </h2>
    
                 
               
                 </div>  
              </div>
           </div>
       </div>
    </div>   

   </section>
</main>

 





     <main class="">
        <section class="build-project">
  
            <div class="container py-3">
                 <div class="row">
                    <div class="col-lg-12 py-5">
                        <div class="content-build-project">
                            <h2>Ready to <br/>
                              build together?</h2>
                        </div>
                        <div class="button-builf-project mt-5">
                          
                        <Link to="/contact">Build a project with us</Link>
                        </div>
                    </div>
                 </div> 
            </div>
        </section>
     </main>






        <Footer activelink='service'/>





    </div>
  )
}

export default Service ;
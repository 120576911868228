import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { Link } from "react-router-dom";

function ValueEngineering() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <SEO
        title='Value | Engeering'
        description='.'
        keywords=''
      />

<Header activelink='engineering'/>


      <main>
         <section class="type-baner">
            <img src="images/valu-engeering.png" alt="" width="100%"  height="100%"/>

           
              
         </section>
      </main>
  




      <main>

          <section className="value-enpadding mt-5">
               
      
      <div class="container">
        <div class="">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-4 mt-5">
            <div class="image-servies-co">
                <img src="images/Preparation-00101.png" alt="" width="100%" height="100%"/>
            </div>
        </div>
        <div class="col-lg-1">

        </div>
          <div class="col-lg-4 mt-5">
              <div class="content-about">
                
                  <p class="number">01</p>
                    <h2 class="">
    
                        Preparation
                    </h2>
    
                  <p>Employer objectives and brief are assimilated to reflect the direction and required end result.</p>
        
            
                 </div>
                 
            </div>
           
      </div>
        </div>
      </div>
  
  
  
  
  
  
      <div class="container">
        <div class="">
      <div class="row  d-flex justify-content-center">
     
     
          <div class="col-lg-4 mt-5">
              <div class="content-about">
                <p class="number">02</p>
                    <h2 class="mt-4">
    
                        Information gathering
                    </h2>
    
                  <p>At this stage the required performance functions are identified. This step determines what functions or performance characteristics are important.</p>
                     
                 </div>
               
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-4 mt-5">
                <div class="image-servies-co">
                    <img src="images/Information gathering.png" alt="" width="100%" height="100%"/>
                </div>
            </div>
  
      
      </div>
        </div>
      </div>





      
      
      <div class="container">
        <div class="">
      <div class="row  d-flex justify-content-center">
        <div class="col-lg-4 mt-5">
            <div class="image-servies-co">
                <img src="images/Analysis.png" alt="" width="100%" height="100%"/>
            </div>
        </div>
        <div class="col-lg-1"></div>
          <div class="col-lg-4 mt-5">
              <div class="content-about">
                <p class="number">03</p>
                    <h2 class="mt-4">
    
                        Analysis
                    </h2>
    
                  <p>Function & Performance versus cost and life cycle benefits are weighed leading to a list of VE candidates.</p>
        
            
                 </div>
              
            </div>
         
           
      </div>
        </div>
      </div>





      <div class="container">
        <div class="">
      <div class="row  d-flex justify-content-center">
      
          <div class="col-lg-4 mt-5">
              <div class="content-about">
                
                  <p class="number">04</p>
                    <h2 class="">
    
                        Alternative generation/Creation
                    </h2>
    
                  <p>At this stage value engineering asks what the various alternative ways of meeting the requirements are.</p>
        
            
                 </div>
                 
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-4 mt-5">
                <div class="image-servies-co">
                    <img src="images/Alternative generationCreation.png" alt="" width="100%" height="100%"/>
                </div>
            </div>
           
      </div>
        </div>
      </div>
  
  
  
  
  
  
      <div class="container">
        <div class="">
      <div class="row d-flex justify-content-center">
      
        <div class="col-lg-4 mt-5">
            <div class="image-servies-co">
                <img src="images/Evaluation.png" alt="" width="100%" height="100%"/>
            </div>
        </div>
        <div class="col-lg-1"></div>
          <div class="col-lg-4 mt-5">
              <div class="content-about">
                <p class="number">05</p>
                    <h2 class="mt-4">
    
                        Evaluation
                    </h2>
    
                  <p>At this stage the alternatives are assessed by evaluating how well they meet the required functions & how great will the cost savings be.</p>
                    
                 </div>
               
            </div>


            
  
      
      </div>
        </div>
      </div>


      <div class="container">
        <div class="">
      <div class="row d-flex justify-content-center">
      
          <div class="col-lg-4 mt-5">
              <div class="content-about">
                
                  <p class="number">06</p>
                    <h2 class="">
    
                        Presentation
                    </h2>
    
                  <p>In the final stage, the best alternative will be chosen and presented to the client for final decision.</p>
        
            
                 </div>
                 
            </div>
            <div class="col-lg-1">
               
            </div>

            <div class="col-lg-4 mt-5">
                <div class="image-servies-co">
                    <img src="images/Presentation.png" alt="" width="100%" height="100%"/>
                </div>
            </div>
          
      </div>
        </div>
      </div>
  
  
  
  
  
  
    
  
  
  
  
  
  








      
      
     
  
  
  
  
  
  
          </section>




        {/*========== MOBILE VIEW TAB====  */}

          <section className="value-enpadding-002 mt-5">
               
      
               <div class="container">
                 <div class="">
               <div class="row d-flex justify-content-center">
               <div class="col-lg-4 mt-5">
                       <div class="content-about">
                         
                           <p class="number">01</p>
                             <h2 class="">
             
                                 Preparation
                             </h2>
             
                           <p>Employer objectives and brief are assimilated to reflect the direction and required end result.</p>
                 
                     
                          </div>
                          
                     </div>
                     <div class="col-lg-1">
         
         </div>
                 <div class="col-lg-4 mt-5">
                     <div class="image-servies-co">
                         <img src="images/Preparation-00101.png" alt="" width="100%" height="100%"/>
                     </div>
                 </div>
             
                
                    
               </div>
                 </div>
               </div>
           
           
           
           
           
           
               <div class="container">
                 <div class="">
               <div class="row  d-flex justify-content-center">
              
              
                   <div class="col-lg-4 mt-5">
                       <div class="content-about">
                         <p class="number">02</p>
                             <h2 class="mt-4">
             
                                 Information gathering
                             </h2>
             
                           <p>At this stage the required performance functions are identified. This step determines what functions or performance characteristics are important.</p>
                              
                          </div>
                        
                     </div>
                     <div class="col-lg-1"></div>
                     <div class="col-lg-4 mt-5">
                         <div class="image-servies-co">
                             <img src="images/Information gathering.png" alt="" width="100%" height="100%"/>
                         </div>
                     </div>
           
               
               </div>
                 </div>
               </div>
         
         
         
         
         
               
               
               <div class="container">
                 <div class="">
               <div class="row  d-flex justify-content-center">
               
                
                   <div class="col-lg-4 mt-5">
                       <div class="content-about">
                         <p class="number">03</p>
                             <h2 class="mt-4">
             
                                 Analysis
                             </h2>
             
                           <p>Function & Performance versus cost and life cycle benefits are weighed leading to a list of VE candidates.</p>
                 
                     
                          </div>
                       
                     </div>

                     <div class="col-lg-4 mt-5">
                     <div class="image-servies-co">
                         <img src="images/Analysis.png" alt="" width="100%" height="100%"/>
                     </div>
                 </div>
                  
                    
               </div>
                 </div>
               </div>
         
         
         
         
         
               <div class="container">
                 <div class="">
               <div class="row  d-flex justify-content-center">
               
                   <div class="col-lg-4 mt-5">
                       <div class="content-about">
                         
                           <p class="number">04</p>
                             <h2 class="">
             
                                 Alternative generation/Creation
                             </h2>
             
                           <p>At this stage value engineering asks what the various alternative ways of meeting the requirements are.</p>
                 
                     
                          </div>
                          
                     </div>
                     <div class="col-lg-1"></div>
                     <div class="col-lg-4 mt-5">
                         <div class="image-servies-co">
                             <img src="images/Alternative generationCreation.png" alt="" width="100%" height="100%"/>
                         </div>
                     </div>
                    
               </div>
                 </div>
               </div>
           
           
           
           
           
           
               <div class="container">
                 <div class="">
               <div class="row d-flex justify-content-center">
               
              
                 <div class="col-lg-1"></div>
                   <div class="col-lg-4 mt-5">
                       <div class="content-about">
                         <p class="number">05</p>
                             <h2 class="mt-4">
             
                                 Evaluation
                             </h2>
             
                           <p>At this stage the alternatives are assessed by evaluating how well they meet the required functions & how great will the cost savings be.</p>
                             
                          </div>
                        
                     </div>

                     <div class="col-lg-4 mt-5">
                     <div class="image-servies-co">
                         <img src="images/Evaluation.png" alt="" width="100%" height="100%"/>
                     </div>
                 </div>
         
         
                     
           
               
               </div>
                 </div>
               </div>
         
         
               <div class="container">
                 <div class="">
               <div class="row d-flex justify-content-center">
               
                   <div class="col-lg-4 mt-5">
                       <div class="content-about">
                         
                           <p class="number">06</p>
                             <h2 class="">
             
                                 Presentation
                             </h2>
             
                           <p>In the final stage, the best alternative will be chosen and presented to the client for final decision.</p>
                 
                     
                          </div>
                          
                     </div>
                     <div class="col-lg-1">
                        
                     </div>
         
                     <div class="col-lg-4 mt-5">
                         <div class="image-servies-co">
                             <img src="images/Presentation.png" alt="" width="100%" height="100%"/>
                         </div>
                     </div>
                   
               </div>
                 </div>
               </div>
           
           
           
           
           
           
             
           
           
           
           
           
           
         
         
         
         
         
         
         
         
               
               
              
           
           
           
           
           
           
                   </section>
      </main>



<main>
   <section class="our-fouc-section-service">
    <div class="container p-5">
       <div class="row">
           <div class="col-lg-12">
              <div class="service-focus">
                <div class="content-about">
                  <span class="Growth">Growth Strategy</span>
                    <h2 class="mt-4">
    
                      We know that every <br/> decision has an impact
                   
                    </h2>
    
                 
               
                 </div>  
              </div>
           </div>
           <div class="col-lg-4 mt-5">
               <div class="value-engeering-icon">
                    <img src="images/value/icon-001.png" alt=""/>
                    <p class="mt-3">Diversifying and expanding into new functional areas and geographic areas</p>
               </div>
           </div>
           <div class="col-lg-4 mt-5">
            <div class="value-engeering-icon">
                 <img src="images/value/icon-002.png" alt=""/>
                 <p class="mt-3">Maintaining performance & competitiveness of existing business.</p>
            </div>
        </div>
        <div class="col-lg-4 mt-5">
          <div class="value-engeering-icon">
               <img src="images/value/icon-003.png" alt=""/>
               <p class="mt-3">Developing and maintaining strong relationships with Clientele and Partners.</p>
          </div>
      </div>
      <div class="col-lg-4 mt-5">
        <div class="value-engeering-icon">
             <img src="images/value/icon-004.png" alt=""/>
             <p class="mt-3">Overcoming Bottlenecks existing in the industry.</p>
        </div>
    </div>
    <div class="col-lg-4 mt-5">
      <div class="value-engeering-icon">
           <img src="images/value/icon-005.png" alt=""/>
           <p class="mt-3">Investing in Human Capital </p>
      </div>
  </div>
  <div class="col-lg-4 mt-5">
    <div class="value-engeering-icon">
         <img src="images/value/icon-006.png" alt=""/>
         <p class="mt-3">Adopting sustainable & green Technologies.</p>
    </div>
</div>

       </div>
    </div>   

   </section>
</main>

 





     <main class="">
        <section class="build-project">
  
            <div class="container py-3">
                 <div class="row">
                    <div class="col-lg-12 py-5">
                        <div class="content-build-project">
                            <h2>Ready to <br/>
                              build together?</h2>
                        </div>
                        <div class="button-builf-project mt-5">
                            <Link to="/contact">Build a project with us</Link>
                        </div>
                    </div>
                 </div> 
            </div>
        </section>
     </main>





      <Footer activelink='engineering'/>

    </div>
  )
}

export default ValueEngineering
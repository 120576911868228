import React,{useEffect,useState} from "react";
import { Link } from 'react-router-dom';

export default function Header({activelink}){
   
    
    return (
        <>
           
           <header>
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    {/* <a className="navbar-brand" href="#"><img src="images/AR-Krishana-Logo 1.png" alt="" /> AS Krishna Associates Ltd</a> */}
                    <Link to="/" class="navbar-brand">
                     <img src="images/ASK_Logo.png" alt="" /> 
                   <br/> AS Krishna Associates Ltd.
                    </Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item" id="desktop-view">
                                {/* <a className="nav-link active" aria-current="page" href="#">About</a> */}
                                <Link to="/about" className={`${activelink=='about' ? 'nav-link nav__link active' : 'nav-link nav__link'}`}>About</Link>
                                <div className="submenu" id="navbarsubmenu">
                                    <ul>
                                        <li>
                                        <Link to="/about"> Overview </Link>
                                        </li>
                                        <li>
                                        <Link to="/our-team">  Leadership</Link>
                                        </li>
                                    </ul>

                                </div>
                            </li>
                            <li className="nav-item mobile-nav"data-bs-toggle="collapse" data-bs-target="#collapseExamplemobie-menu" aria-expanded="false" aria-controls="collapseExamplemobie-menu">
                                {/* <a className="nav-link active" aria-current="page" href="#">About</a> */}
                                <Link to="/about" className={`${activelink=='about' ? 'nav-link nav__link active' : 'nav-link nav__link'}`}>About</Link>
                             
                            </li>
                            <div className="second collapse" id="collapseExamplemobie-menu">
                                    <ul>
                                        <li>
                                        <Link to="/about"> Overview </Link>
                                        </li>
                                        <li>
                                        <Link to="/our-team">  Leadership</Link>
                                        </li>
                                    </ul>

                                </div>
                           
                            <li className="nav-item">
                                {/* <a className="nav-link" href="#">Services</a> */}
                                <Link to="/service" className={`${activelink=='service' ? 'nav-link nav__link active' : 'nav-link nav__link'}`}>Services</Link>
                            </li>
                            <li className="nav-item">
                               
                                <Link to="/value-engineering" className={`${activelink=='engineering' ? 'nav-link nav__link active' : 'nav-link nav__link'}`}>Value Engineering</Link>
                            </li>
                            <li className="nav-item">
                                
                                <Link to="/projects" class="nav-link" className={`${activelink=='project' ? 'nav-link nav__link active' : 'nav-link nav__link'}`}>Projects</Link>
                            </li>

                            <li className="nav-item d-flex align-items-center logo-plus-button">
                                <img src="images/four-dot.png" alt="" className="logo-dots" />                               
                                <Link to="/contact" className={`${activelink=='contact' ? 'nav-link nav__link active' : 'nav-link nav__link'}`}>Get in touch</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>

        </>
    );
};
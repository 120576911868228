import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ activelink }) => {
  return (
    <>
      <footer className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg">
              <div className="footer-logo">
                <img src="images/ASK_Logo.png" alt="" />
                <br /> <span>AS Krishna Associates Ltd.</span>
              </div>
            </div>
            <div className="col-lg">
              <div className="footer-adress">
                <span>Address</span> <br />
                1601-1603, DLF Corporate Green Tower 4, Sector 74A, Gurugram, Haryana 122004
              </div>
            </div>
            <div className="col-lg">
              <div className="footer-adress">
                <span>Email</span> <br />
                admin@askrishna.in
              </div>
            </div>
            <div className="col-lg">
              <div className="footer-adress">
                <span>Phone</span> <br />
                0120-45181127
              </div>
            </div>
            <div className="col-lg">
              <div className="footer-adress">
                <span>Social media</span> <br />
                <div className="div-socail">
                  <i className="fa-brands fa-facebook-f"></i>
                  <i className="fa-brands fa-twitter"></i>
                  <i className="fa-brands fa-linkedin-in"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg">
              <div className="footer-adress-01">
                <Link
                  to="/about"
                  className={`${
                    activelink == "about"
                      ? "nav-link nav__link active"
                      : "nav-link nav__link"
                  }`}
                >
                  About
                </Link>
              </div>
            </div>
            <div className="col-lg">
              <div className="footer-adress-01">
                <Link
                  to="/service"
                  className={`${
                    activelink == "service"
                      ? "nav-link nav__link active"
                      : "nav-link nav__link"
                  }`}
                >
                  Services
                </Link>
              </div>
            </div>

            <div className="col-lg">
              <div className="footer-adress-01">
                <Link
                  to="/value-engineering"
                  className={`${
                    activelink == "engineering"
                      ? "nav-link nav__link active"
                      : "nav-link nav__link"
                  }`}
                >
                  Value Engineering
                </Link>
              </div>
            </div>
            <div className="col-lg">
              <div className="footer-adress-01">
                <Link
                  to="/projects"
                  className={`${
                    activelink == "project"
                      ? "nav-link nav__link active"
                      : "nav-link nav__link"
                  }`}
                >
                  Projects
                </Link>
              </div>
            </div>

            <div className="col-lg">
              <div className="footer-adress-01">
                <Link
                  to="/contact"
                  className={`${
                    activelink == "contact"
                      ? "nav-link nav__link active"
                      : "nav-link nav__link"
                  }`}
                >
                  Get in touch
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-9">
              <div className="none-hero">
                © 2024 AS Krishna Associates Ltd. | All Rights Reserved
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";


import SEO from "../components/seo";
import axios from "axios";


const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [full_name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [organization_name, setOrganization] = useState("");
  const [description, setDescription] = useState("");
  const [showconfirmation, setShowConfirmation] = useState("No");
  const [isFetching, setisFetching] = useState(false);


  const submitForm = (event) => {
    setisFetching(true);
    event.preventDefault();
    axios
      .post("https://api.acolabz.com/askrishna/contactus", {
        full_name,
        organization_name,
        phone,
        email,
        description,
      })
      .then(data => {
     
        setisFetching(false);
        setShowConfirmation("Yes");
        alert("Successfull Sumit Your Request");
        setFullName("")
        setEmail("")
        setPhone("")
        setOrganization("")
        setDescription("")
      })
  };



  return (
    <>

<SEO
        title='Contact'
        description='.'
        keywords=''
      />
       <Header activelink='contact'/>
      <main>
        <section className="contact-normal">
          <div class="container">
            <div class="content-about py-5">
              <span class="log-bard">Contact</span>
              <h2 class="mt-4">

                We’d love to hear from <br /> you

              </h2>


              <p>Building the future of the construction industry, one project at a time.</p>



            </div>
          </div>
        </section>
      </main>
      <div>
        <section className="contact-usmain-page">
          <div class="container mb-5">
            <div class="row">
              <div class="col-lg-6">
                <div class="from-div">
                  <h3>How can we help?</h3>
                  <form action="" onSubmit={submitForm}>
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-lg-6 mt-5">
                          <label for="" class="labe-contact mb-3">Full name</label> <br />
                          <input
                            type="text"
                            class="form-control contact"
                            id="txtName"
                            value={full_name}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                            aria-describedby="Enter Name"
                            placeholder="Please Enter Your Name"
                          />
                        </div>

                        <div class="col-lg-6 mt-5">
                          <label for="" class="labe-contact mb-3">Organization name</label> <br />
                          <input
                            type="text"
                            class="form-control contact"
                            id="txtName"
                            value={organization_name}
                            onChange={(e) => setOrganization(e.target.value)}
                            required
                            aria-describedby="Enter Organization"
                            placeholder="Enter Organization"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 mt-5">
                          <label for="" class="labe-contact mb-3">Phone number</label> <br />
                          <input
                            type="number"
                            class="form-control contact"
                            id="txtName"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                            aria-describedby="Enter Phone"
                            placeholder="Enter Phone"
                          />
                        </div>

                        <div class="col-lg-6 mt-5">
                          <label for="" class="labe-contact mb-3">Email address</label> <br />
                          <input
                            type="email"
                            class="form-control contact"
                            id="txtEmail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            
                            required
                            placeholder="Enter Your Email"
                          />
                        </div>
                      </div>
                      <div class="row mt-5">
                        <div class="col-lg-12">
                          <label for="" class="labe-contact mb-3">Description</label> <br />
                          <textarea
                            name=""
                            id="txtMessage"
                            cols="30"
                            rows="4"
                            class="form-control contact"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          ></textarea>
                        </div>
                      </div>

                      <div class="col-lg-12 mt-5">
                      <button type="submit" class="contctsend">
                        <span className="spinnercontainer">{isFetching ? <span id="spinner"><i class="fa fa-spinner fa-spin"></i></span> : null} </span>Submit<span className="spinnercontainer"></span>
                      </button>
                      </div>

                    </div>

                  </form>
                </div>
              </div>


              <div class="col-lg-6">
                <div class="map-frame">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14037.61094984493!2d76.9802628662537!3d28.40710219915695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3d4529ac5047%3A0x6dfe962535dafd2c!2sSector%2074A%2C%20Gurugram%2C%20Haryana%20122004!5e0!3m2!1sen!2sin!4v1677497095876!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer activelink='contact'/>
    </>
  );
};

export default Contact;
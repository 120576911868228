import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import HeaderLogo from "../components/headerlogo";
import SEO from "../components/seo";


const Home = () => {
   
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <SEO
                title='Home | AS Krishna Associates Ltd.'
                description=''
                keywords=''
            />
             <Header activelink='home'/>
           

            <main>
                <section className="banner-images">
                    <div className="container">
                        <div className="col-lg-6">
                            <div className="banner-text">
                                <h1 className="text-white mt-2 mb-5">Crafting India’s Future: Quality Infrastructure, Sustainable Solutions.</h1>
                                    <div className="btn ms-3">
                                        <Link to="/About" className="btn-learn-more"> learn more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
            </main>
            <main>
                <section className="mt-5 ">
                    <div className="container px-3">
                        <div className="row">
                        <div className="col-lg-12">
                                <div className="content-about conten-head">
                                    <span> Our Esteemed Clients</span> 
                                </div>
                                   
                         </div>
                        </div>                       
                        <div className="row mt-1 comany-logo-section">
                       
                           
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                            <div className="company-logo">
                                           <a href="https://delhijalboard.delhi.gov.in/" target="_blank"> <img src="images/AS-Krishna-Images/DJB, Delhi.jpg" alt=""/></a>
                                        </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                                     <a href="https://www.ntpc.co.in/" target="_blank">    <img src="images/logos/NTPC_Logo.png" alt="NTPC"/></a>
                                                     </div>
                                         </div>  
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                         <a href="https://www.larsentoubro.com/" target="_blank"> <img className="fixed-width-logo" src="images/logos/L_and_t.png" alt="Delhi Jal Board"/></a>
                                                     </div>
                                         </div>

                                 <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                         <a href="https://www.acclimited.com/" target="_blank">   <img src="images/logos/ACC_Limited_logo.png" alt=""/></a>
                                                     </div>
                                         </div>
                                         <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                         <a href="https://www.delhimetrorail.com/" target="_blank">  <img className="fixed-width-logo" src="images/logos/DMRC.png" alt=""/></a>
                                                     </div>
                                         </div>
                                         <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                         <a href="https://www.apcoinfra.com/" target="_blank"> <img src="images/logos/apco-infratech_9c4c9c.png" alt=""/></a>
                                                     </div>
                                         </div>
                                         <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                         <a href="http://jalindia.com/" target="_blank"> <img src="images/logos/Jaiprakash associates limited.png" alt=""/></a>
                                                     </div>
                                         </div>
                                         <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                         <a href="https://www.lafarge.com.eg/en" target="_blank">   <img  src="images/logos/Lafarge2.png" alt=""/></a>
                                                     </div>
                                         </div>
                                         <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                         <a href="https://mcdonline.nic.in/portal" target="_blank"> <img className="fixed-width-logo" src="images/logos/Municipal Corporation of Delhi.png" alt=""/></a>
                                                     </div>
                                         </div>  
                                         <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                         <a href="https://www.tataprojects.com/" target="_blank">  <img className="fixed-width-logo" src="images/logos/Tata_logo.png" alt=""/></a>
                                                     </div>
                                         </div>   
                                        
                                         <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                         <a href="https://spsingla.com/" target="_blank"> <img className="fixed-width-logo" src="images/logos/S.P Singla construction Private Limited.png" alt="S.P Singla construction Private Limited"/></a>
                                                     </div>
                                         </div>  
                                         <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                         <a href="http://www.mcambala.gov.in/" target="_blank">  <img className="fixed-width-logo" src="images/logos/Municipal-Corporation-Ambala.png" alt=""/></a>
                                                     </div>
                                         </div>  
                                         <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                         <a href="https://ulbharyana.gov.in/Rohtak/248" target="_blank"> <img src="images/logos/Municipal-Corporation-Rohtak.png" alt=""/></a>
                                                     </div>
                                         </div>  
                                         <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                         <a href="https://karnal.gov.in/public-utility/municipal-corporation-karnal/" target="_blank">   <img className="fixed-width-logo" src="images/logos/Municipal Corporation, Karnal.png" alt=""/></a>
                                                     </div>
                                         </div>  
                                         <div className="col-lg-2 col-md-4 col-sm-6">
                                         
                                         <div className="company-logo">
                                         <a href="https://www.shapoorjipallonji.com/" target="_blank"> <img src="images/logos/Shapoorji-Pallonji-Engineering.png" alt=""/></a>
                                                     </div>
                                         </div>       

                        </div>
                    </div>
                </section>
            </main>
            <main>
                <section className="mt-5 py-5">
                    <div className="container px-3">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content-about">
                                    <span>About us</span>
                                    <h2>
                                    Safety <br /> Professionalism  <br /> Timeliness <br/> 
                                        </h2>

                                            <p>
                                            At AS Krishna Associates, we're more than just a construction company - we're 
                                            your partners in building your dreams. With a deep commitment to excellence
                                             and a passion for precision, we bring your vision to life with utmost care
                                              and attention to detail. Our team of experts is dedicated to delivering 
                                              exceptional results, on time and within budget, all while prioritizing
                                               safety and sustainability. Choose AS Krishna Associates for your next 
                                               project and discover the difference where quality meets affordability and 
                                               innovation meets expertise
                                            </p>

                                            <div className="btn-lern-about mt-5">
                                                <Link to="/About" className="abot-learn-more"> learn more</Link>
                                            </div>
                                        </div>
                                </div>
                                   
                                
                            </div>
                        </div>
                </section>
            </main>

           

            <main>
                <section className="our-sectore py-5 mt-5">

                    <div className="container">
                        <div className="col-lg-12">
                            <div className="heading px-5 mb-5">
                                <h2>Our Service</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card-forour-sector px-5">
                                    <img src="images/building.png" alt=""/>

                                        <h3>Infrastructure Developement </h3>

                                        <p>
                                        Commercial building projects require exceptional attention to detail, innovative
                                        design solutions, and the ability to manage complex construction processes.
                                         From retail spaces to office buildings, we are committed to delivering 
                                         high-quality commercial building projects that exceed expectations.
                                             </p>





                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card-forour-sector px-5">
                                    <img src="images/Capture.png" alt=""/>

                                        <h3>Waste Management</h3>

                                        <p>
                                          Our waste management projects are not just about keeping the environment clean, but also 
                                        about promoting sustainability and responsible resource management. With innovative solutions
                                         and a dedicated team, we ensure efficient waste disposal and recycling for a better tomorrow.
                                              </p>




                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card-forour-sector px-5">
                                    <img src="images/Highways.png" alt=""/>

                                        <h3>Construction Logistics </h3>

                                        <p>
                                        From interstates to local roads, our highway projects are more than just 
                                        pavement and asphalt. They connect communities, improve safety, and facilitate 
                                        economic growth. With a focus on quality, safety, and innovation, we build
                                         highways that stand the test of time and exceed expectations.
                                             </p>




                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </main>




           

                    <main>
                        <section className="mt-5 pro-inde">
                            <div className="container">
                                <div>
                                    <div className="col-lg-4">
                                        <div className="content-about">
                                            <span className="">Projects</span>
                                            <h2 className="mt-4">

                                                Delivering our clients more project clarity, greater  insight, and less chaos.
                                                </h2>




                                                </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="button-type-button-learn">
                                                <Link to="/projects"  className="abot-learn-more">View all projects</Link>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </section>
                    </main>

                    <main>

                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 px-3">
                                        <div className="card-as">
                                            <div className="as-image-card">
                                                <img src="images/Residential Project.png" alt="" width="100%" height="100%"/>
                                            </div>
                                            <div className="card-as-content mt-3">
                                                <h3 className="as-hover">Residential Project</h3>
                                                <i className="fa-solid fa-arrow-right-long"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 px-3">
                                        <div className="card-as">
                                            <div className="as-image-card">
                                                <img src="images/DJB, Ranhola, Delhi.png" alt="" width="100%" height="100%"/>
                                            </div>
                                            <div className="card-as-content mt-3">
                                                <h3 className="as-hover">DJB, Ranhola, Delhi</h3>
                                                <i className="fa-solid fa-arrow-right-long"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 px-3">
                                        <div className="card-as">
                                            <div className="as-image-card">
                                                <img src="images/Sewerage System, Amrut Rohtak.png" alt="" width="100%" height="100%"/>
                                            </div>
                                            <div className="card-as-content mt-3">
                                                <h3 className="as-hover">Sewerage System, Amrut Rohtak</h3>
                                                <i className="fa-solid fa-arrow-right-long"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>

                    <main className="mt-5">
                        <section className="build-project">

                            <div className="container py-3">
                                <div className="row">
                                    <div className="col-lg-12 py-5">
                                        <div className="content-build-project">
                                            <h2>Ready to <br/>
                                                build together?</h2>
                                        </div>
                                        <div className="button-builf-project mt-5">
                                            <Link to="/contact">Build a project with us</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>


            <Footer/>
        </>
    );
};

export default Home;
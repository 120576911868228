import React, { useState, useEffect } from "react";
import Footer from '../components/footer'
import Header from '../components/header'
import SEO from "../components/seo";
import { Link } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';


function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <SEO
        title='Project'
        description='.'
        keywords=''
      />

      <Header activelink='project' />



      <main>
        <section class="type-baner">
          <img src="images/Project.png" alt="" width="100%" />



        </section>
      </main>









      <main>
        <section class="">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 py-5">
                <div class="service-focus">
                  <div class="content-about pt-5">
                    <span class="log-bard">Our Work</span>
                    <h2 class="mt-4">

                      Projects

                    </h2>


                    <p>
                      AS Krishna Associates has a track record of exceeding client expectations by delivering
                      exceptional projects. Every project, from commercial buildings and residential complexes
                      to highways and waste management projects, is completed with meticulous attention to detail
                      and a focus on quality. With innovative design solutions, efficient project management, and
                      a commitment to sustainability, AS Krishna Associates has delivered numerous successful
                      projects across India. Trust us to deliver your next project with excellence and a passion
                      for exceeding expectations.
                    </p>



                  </div>


                </div>
              </div>
            </div>
          </div>

        </section>
      </main>




      <main>
        <section>
          <div class="container">

            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="All" value="1" />
                    <Tab label="Commercial Building" value="2" />
                    <Tab label="Waste management" value="3" />
                    <Tab label="Highways" value="4" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <div class="gallery mt-5">
                    <div class="image" data-category="Waste">
                      <img src="images/AS-Krishna-Images/Sewerage System_ Amrut_ Rohtak.jpg" alt="" width="100%" class="mb-3" />
                      <span>Waste Management</span>
                      <h5 class="mt-2">Sewerage System, Amrut, Rohtak</h5>



                    </div>

                    <div class="image" data-category="Waste">
                      <img src="images/AS-Krishna-Images/peripheral sewer lines in Ranhola group of companies, Delhi.jpg" alt="" width="100%" class="mb-3" />
                      <span>Waste Management</span>

                      <h5 class="mt-2">Peripheral Sewer line in Ranhola Group of Companies, Delhi</h5>


                    </div>

                    <div class="image" data-category="Commercial">
                      <img src="images/AS-Krishna-Images/Construction-Gurugram.jpg" alt="" width="100%" class="mb-3" />


                      <span>Commercial Building</span>

                      <h5 class="mt-2">Construction of Offices - Gurugram, Palwal</h5>

                    </div>
                    <div class="image" data-category="Commercial">
                      <img src="images/AS-Krishna-Images/Construction-nuhPalwal.jpg" alt="" width="100%" class="mb-3" />


                      <span>Commercial Building</span>
                      <h5 class="mt-2">Construction of office spaces at Nuh, Palwal & Rewari</h5>


                    </div>
                    <div class="image" data-category="Commercial">
                      <img src="images/AS-Krishna-Images/Office Building Construction at Rohtak.jpg" alt="" width="100%" class="mb-3" />


                      <span>Commercial Building</span>
                      <h5 class="mt-2">Construction of Office Building at Rohtak</h5>

                    </div>


                    <div class="image" data-category="Highways">
                      <img src="images/AS-Krishna-Images/Highways-01.jpg" alt="" width="100%" class="mb-3" />
                      <span>Highways</span>


                      <h5 class="mt-2">Infrastructure Road Construction</h5>

                    </div>

                    {/* <div class="image" data-category="Highways">
                      <img src="images/AS-Krishna-Images/Residential Projects.jpg" alt="" width="100%" class="mb-3" />
                      <span>Commercial Building</span>


                      <h5 class="mt-2">Residential buildings</h5>

                    </div> */}
                    <div class="image" data-category="Waste management">
                      <img src="images/AS-Krishna-Images/Delhi_Jal_Board_1.jpg" alt="" width="100%" class="mb-3" />
                      <span>Waste Management</span>


                      <h5 class="mt-2">Delhi Jal Board, Ranhola, Delhi</h5>

                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4">

                        <div class="image" data-category="Commercial">
                          <img src="images/AS-Krishna-Images/Construction-Gurugram.jpg" alt="" width="100%" class="mb-3" />


                          <span>Commercial Building</span>

                          <h5 class="mt-2">Construction of Offices - Gurugram, Palwal</h5>

                        </div>
                      </div>


                      <div className="col-lg-4">

                        <div class="image" data-category="Commercial">
                          <img src="images/AS-Krishna-Images/Construction-nuhPalwal.jpg" alt="" width="100%" class="mb-3" />


                          <span>Commercial Building</span>
                          <h5 class="mt-2">Construction of office spaces at Nuh, Palwal & Rewari</h5>


                        </div>
                      </div>



                      <div className="col-lg-4">

                        <div class="image" data-category="Commercial">
                          <img src="images/AS-Krishna-Images/Office Building Construction at Rohtak.jpg" alt="" width="100%" class="mb-3" />


                          <span>Commercial Building</span>
                          <h5 class="mt-2">Construction of Office Building at Rohtak</h5>

                        </div>
                      </div>
                    </div>
                  </div>






                </TabPanel>
                <TabPanel value="3">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4">
                        <div class="image" data-category="Waste">
                          <img src="images/AS-Krishna-Images/Sewerage System_ Amrut_ Rohtak.jpg" alt="" width="100%" class="mb-3" />
                          <span>Waste management</span>
                          <h5 class="mt-2">Sewerage System, Amrut, Rohtak</h5>



                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div class="image" data-category="Waste">
                          <img src="images/AS-Krishna-Images/peripheral sewer lines in Ranhola group of companies, Delhi.jpg" alt="" width="100%" class="mb-3" />
                          <span>Waste management</span>

                          <h5 class="mt-2">Peripheral Sewer line in Ranhola Group of Companies, Delhi</h5>


                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div class="image" data-category="Waste">
                        <img src="images/AS-Krishna-Images/Delhi_Jal_Board_1.jpg" alt="" width="100%" class="mb-3" />
                      <span>Waste management</span>


                      <h5 class="mt-2">Delhi Jal Board, Ranhola, Delhi</h5>


                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="4">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4">
                        <div class="image" data-category="Highways">
                          <img src="images/AS-Krishna-Images/Highways-01.jpg" alt="" width="100%" class="mb-3" />
                          <span>Highways</span>


                          <h5 class="mt-2"> Infrastructure Road Construction</h5>

                        </div>
                      </div>
                    </div>
                  </div>

                </TabPanel>
              </TabContext>
            </Box>





          </div>
        </section>
      </main>






      <main class="mt-5 pt-5">
        <section class="build-project">

          <div class="container py-3">
            <div class="row">
              <div class="col-lg-12 py-5">
                <div class="content-build-project">
                  <h2>Ready to <br />
                    build together?</h2>
                </div>
                <div class="button-builf-project mt-5">
                  <Link to="/contact">Build a project with us</Link>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer activelink='project' />



    </div>
  )
}

export default Projects
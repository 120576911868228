import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link}
    from 'react-router-dom';


import Header from './components/header';
import Footer from './components/footer';

import Home from './pages/index';
import About from './pages/about';
import Service from './pages/service';
import ValueEngineering from './pages/valueEngineering';
import Projects from './pages/projects';
import OurTeam from './pages/our-team';
import Contact from './pages/contact';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/about' element={<About/>} />
            <Route path='/service' element={<Service />} />
            <Route path='/value-engineering' element={<ValueEngineering />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/contact' element={<Contact/>} />   
            <Route path='/our-team' element={<OurTeam/>} />      
        </Routes>
      </Router>
      </HelmetProvider>
  );
  }
    
  export default App;
